import React from "react";

import Typography from "@material-ui/core/Typography"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import CardHeader from "@material-ui/core/CardHeader"
import Button from "@material-ui/core/Button";
import Grid from '@material-ui/core/Grid';
import { useConfirm } from 'material-ui-confirm';
// import Alert from "@material-ui/lab/Alert"
import CircularProgress from "@material-ui/core/CircularProgress";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Radio from "@material-ui/core/Radio"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import RadioGroup from "@material-ui/core/RadioGroup"
import Checkbox from "@material-ui/core/Checkbox"
import Link from "@material-ui/core/Link"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import DialogActions from "@material-ui/core/DialogActions"
import Divider from "@material-ui/core/Divider"
import ReCAPTCHA from "react-google-recaptcha";
// import PayplugReturnOk from "./PayplugReturnNok"
// *** Redux
import { useDispatch } from 'react-redux';
import { shallowEqual, useSelector } from 'react-redux';
import { useState } from "react"
import { useEffect } from "react"
import { useTranslation } from "react-i18next";
// *** Zopa components
import { sendToDolibarr } from "../../services/services/RegistrationActions"
import { setReduxPaymentmode } from "../../services/services/RegistrationActions"
import { getConfigurationdepositpercent } from "../../services/GlobalLists/GlobalListsActions";
// *** Styling
// import clsx from "clsx";
import { makeStyles } from '@material-ui/core/styles'
const useStyles = makeStyles((theme) => ({
    root: {
        background: "linear-gradient(45deg, #F4F6F6 30%, #F2F3F4 90%)",
        padding: theme.spacing(2),
    },
    pageTitle: { color: "#6b6c6d" },
    blockCardHeaderStyle:
        theme.palette.blockCardHeaderStyle,
    blockCardHeaderIcon:
        theme.palette.blockCardHeaderIcon
}));

const Accordion = withStyles({
    root: {
        border: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        backgroundColor: 'rgba(0, 0, 0, .1)',
        borderBottom: '1px solid rgba(0, 0, 0, .125)',
        marginBottom: -1,
        minHeight: 56,
        '&$expanded': {
            minHeight: 56,
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiAccordionDetails);



export default function SendForm({ location }) {

    const classes = useStyles();
    const dispatch = useDispatch();
    const confirm = useConfirm();
    const { t } = useTranslation();

    // *** Redux state
    const loadingOrder = useSelector(state => state.registrationReducer.loadingOrder, shallowEqual);
    const loccomputedOrderlines = useSelector(state => state.registrationReducer.computedOrderlines, shallowEqual);
    const configuration = useSelector(state => state.globalListsReducer.configuration, shallowEqual);
    const registration = useSelector(state => state.registrationReducer.registration, shallowEqual);

    const isRegistrationValidated = useSelector(state => state.registrationReducer.isRegistrationValidated, shallowEqual);
    const orderTotalAmount = useSelector(state => state.registrationReducer.orderTotalAmount, shallowEqual);

    const withoutdeposit = registration && registration.product && registration.product.array_options && registration.product.array_options.options_sta_withoutdeposit;

    const payment = useSelector(state => state.registrationReducer.payment, shallowEqual);
    const [captchaopen, setcaptchaopen] = React.useState(false);

    const webOrderResult = useSelector(state => state.registrationReducer.webOrderResult, shallowEqual);

    const [createdOrder, setcreatedOrder] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const [paymentChoice, setpaymentChoice] = useState("");
    const [cgvvalidated, setcgvvalidated] = useState(false);
    const [isHostingRequested, setisHostingRequested] = useState(0);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    useEffect(() => {
        if (registration && registration.tabBusyHosting) {
            let isHostingRequesteda = registration.tabBusyHosting.reduce((a, b) => a + (b === true ? 1 : 0), 0)
            setisHostingRequested(isHostingRequesteda > 0);
        }
        if (!isRegistrationValidated)
            setExpanded(false);
    }, [isRegistrationValidated]);



    function handlePaymentChoiceChange(event) {
        // let toto = 2;
        dispatch(setReduxPaymentmode({ ...payment, paymentMode: event.target.value }));
        setpaymentChoice(event.target.value);

    }

    /***
     * CGV validation
     */
    function handleBoxChange(event) {
        if (event.target) {
            if (event.target.name === 'cgv')
                setcgvvalidated(!cgvvalidated);
        }
    }
    /**
     * Change payment mode
     * @param {*} event 
     */
    function submitFormhostingValidation(event) {
        dispatch(setReduxPaymentmode({ ...payment, paymentMode: 'validationHosting' }));
        submitForm(event);
    }

    /**
   * Change payment mode
   * @param {*} event 
   */
    function submitFormFreeValidation(event) {
        dispatch(setReduxPaymentmode({ ...payment, paymentMode: 'validationFreeOfCharge' }));
        submitForm(event);
    }

    /**
     * User has requested to send subscription, we open the captcha
     * @param {*} event 
     */
    function submitForm(event) {
        setcaptchaopen(true);
        event.preventDefault();
    }


    /**
     * After captcha validation, send order to dolibarr
     * @param {*} value 
     */
    function onCaptchaChange(value) {
        setcaptchaopen(false);
        setcreatedOrder(true);
        try {
            dispatch(sendToDolibarr(value));
        } catch (error) {
            confirm({
                description: error.message,
                title: 'Erreur de configuration',
                cancellationText: "",
                confirmationButtonProps: { autoFocus: true }
            })
        }
    }

    function handleCaptchaCancel() {
        setcaptchaopen(false);
    }

    /**
  * executed after order creation
  */
    useEffect(() => {
        if (webOrderResult) {
            let jsonreturnedData = JSON.parse(webOrderResult);
            setcreatedOrder(jsonreturnedData && jsonreturnedData.orderId > 0 ? true : false);

            if (!paymentChoice.includes('carte'))
                window.location.assign("/?retour=order")
            else
                window.location.assign(window.location.protocol +
                    "//" +
                    window.location.hostname + "/dolibarr/custom/helloassopay/public/start.php?ref=" + jsonreturnedData.invoiceId);
        }
    }, [webOrderResult]);

    /**
     * 
     */
    // function refreshPage() {
    //     window.location.assign("/");
    // }

    return (
        <div>
            <Card style={{ margin: "10px" }} >
                <CardHeader title={t('send.title')} subheader="" style={{ backgroundColor: "#DFDFDB" }}>
                </CardHeader>

                <CardContent>
                    <Grid spacing={2} item md={12} xs={12} container>
                        <Grid item md={12} xs={12}>
                            <FormControlLabel
                                disabled={!isRegistrationValidated}
                                control={<Checkbox checked={cgvvalidated} onChange={handleBoxChange} name="cgv" />}
                                label={(t('send.validatecgv'))}
                            />
                        </Grid>
                    </Grid>

                    <Typography><Link href="#cgvanchor">{(t('send.seecgv'))}</Link> </Typography>
                    {loadingOrder && <CircularProgress color="secondary" />}

                    {/* // *** hosting */}
                    <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} disabled={!isRegistrationValidated || !cgvvalidated || !isHostingRequested}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header">
                            <Typography className={classes.heading}>{(t('send.hostingrequested'))}</Typography>
                            <Typography className={classes.secondaryHeading}></Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid spacing={2} item md={12} xs={12} container>
                                <Typography align="left">
                                    {(t('send.hosting'))}
                                </Typography>
                                <Grid item md={12} xs={12}>
                                    <Button onClick={submitFormhostingValidation} disabled={isRegistrationValidated !== true || (createdOrder && createdOrder > 0) ? true : false || !cgvvalidated} color="primary" style={{ marginTop: "20px" }} variant="outlined" >{(t('send.sendregistration'))}</Button>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>

                    {/* // *** pay full */}
                    <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} disabled={!isRegistrationValidated || !cgvvalidated || isHostingRequested || isHostingRequested || orderTotalAmount === 0}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2bh-content"
                            id="panel2bh-header"
                        >
                            <Typography className={classes.heading}>{(t('send.payfullamount'))}</Typography>
                            <Typography className={classes.secondaryHeading}>

                            </Typography>
                        </AccordionSummary>

                        <AccordionDetails>
                            <Grid spacing={1} item md={12} xs={12} container
                                direction="row"
                                justify="space-evenly"
                                alignItems="center">
                                <Grid spacing={2} item md={12} xs={12} container >
                                    < Grid item md={4} xs={12}>
                                        <Typography align="left">{(t('send.wanttopayby'))}</Typography>
                                        <RadioGroup label="" name="gender1" value={paymentChoice} onChange={handlePaymentChoiceChange}>
                                            <FormControlLabel value="totalcarte" control={<Radio />} label={(t('send.paybycb'))} />
                                            <FormControlLabel value="totalcheque" control={<Radio />} label={(t('send.paybycheck'))} />
                                            <FormControlLabel value="totalvirement" control={<Radio />} label={(t('send.paybytransfert'))} />
                                        </RadioGroup>
                                    </Grid>
                                    < Grid item md={8} xs={12} >
                                        {paymentChoice.includes('totalcarte') && <Grid container spacing={1} md={12}>
                                            <Typography align="left">{(t('send.soldecarte'))}</Typography>

                                            <Grid item md={12} xs={12}>
                                                <Button onClick={submitForm} disabled={isRegistrationValidated !== true || (createdOrder && createdOrder > 0) ? true : false || !cgvvalidated} color="primary" style={{ marginTop: "20px" }} variant="outlined" >{(t('send.sendregistration'))}</Button>
                                            </Grid>
                                        </Grid>}
                                        {paymentChoice.includes('totalcheque') && <Grid container spacing={2} md={12}>
                                            <Grid item md={12} xs={12}>
                                                <Typography align="left">
                                                    <ul>
                                                        <li> {(t('send.soldecheque1'))} {new Intl.NumberFormat("fr-FR", {
                                                            style: "currency",
                                                            currency: "EUR",
                                                        }).format(loccomputedOrderlines.reduce((a, b) => a + parseFloat(b.total_ttc), 0))
                                                        } {(t('send.soldecheque2'))}
                                                        </li>
                                                        <li>{(t('send.soldecheque3'))}
                                                        </li><li>{(t('send.soldecheque4'))}
                                                        </li>
                                                    </ul>
                                                    {(t('send.soldecheque5'))}
                                                </Typography>
                                                <Button onClick={submitForm} disabled={isRegistrationValidated !== true || (createdOrder && createdOrder > 0) ? true : false || !cgvvalidated} color="primary" style={{ marginTop: "20px" }} variant="outlined" >{(t('send.sendregistration'))}</Button>

                                            </Grid>
                                        </Grid>}
                                        {paymentChoice.includes('totalvirement') && <Grid container spacing={2} md={12}>
                                            <Grid item md={12} xs={12}>
                                                <Typography align="left">
                                                    <ul>
                                                        <li> {(t('send.soldetransfert1'))} {new Intl.NumberFormat("fr-FR", {
                                                            style: "currency",
                                                            currency: "EUR",
                                                        }).format(loccomputedOrderlines.reduce((a, b) => a + parseFloat(b.total_ttc), 0))
                                                        } {(t('send.soldetransfert2'))}
                                                        </li>
                                                        <li>{(t('send.soldetransfert3'))}
                                                        </li>
                                                        <li>{(t('send.soldetransfert4'))}
                                                        </li>
                                                    </ul>
                                                    {(t('send.soldetransfert5'))}
                                                </Typography>
                                                <Button onClick={submitForm} disabled={isRegistrationValidated !== true || (createdOrder && createdOrder > 0) ? true : false || !cgvvalidated} color="primary" style={{ marginTop: "20px" }} variant="outlined" >{(t('send.sendregistration'))}</Button>
                                            </Grid>
                                        </Grid>}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>

                    {/* // *** Deposit */}
                    <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')} disabled={!isRegistrationValidated || !cgvvalidated || isHostingRequested || orderTotalAmount === 0 || withoutdeposit}>

                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel3bh-content"
                            id="panel3bh-header">
                            <Typography className={classes.heading}>{(t('send.paydeposit'))}</Typography>
                            <Typography className={classes.secondaryHeading}>
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid spacing={1} item md={12} xs={12} container
                                direction="row"
                                justify="space-evenly"
                                alignItems="center">
                                <Grid spacing={2} item md={12} xs={12} container >
                                    < Grid item md={4} xs={12}>
                                        <Typography align="left">{(t('send.wanttopayby'))}</Typography>
                                        <RadioGroup label="" name="gender1" value={paymentChoice} onChange={handlePaymentChoiceChange}>
                                            <FormControlLabel value="acomptecarte" control={<Radio />} label={(t('send.paybycb'))} />
                                            <FormControlLabel value="acomptecheque" control={<Radio />} label={(t('send.paybycheck'))} />
                                            <FormControlLabel value="acomptevirement" control={<Radio />} label={(t('send.paybytransfert'))} />
                                        </RadioGroup>
                                    </Grid>
                                    < Grid item md={8} xs={12} >
                                        {paymentChoice.includes('acomptecarte') && <Grid container spacing={1} md={12}>
                                            <Typography align="left"> {(t('send.acomptecarte'))}</Typography>
                                            <Grid item md={12} xs={12}>
                                                <Button onClick={submitForm} disabled={isRegistrationValidated !== true || (createdOrder && createdOrder > 0) ? true : false || !cgvvalidated} color="primary" style={{ marginTop: "20px" }} variant="outlined" >{(t('send.sendregistration'))}</Button>
                                            </Grid>
                                        </Grid>
                                        }

                                        {paymentChoice.includes('acomptecheque') && <Grid container spacing={2} md={12}>
                                            <Grid item md={12} xs={12}>
                                                <Typography align="left">
                                                    <ul>
                                                        <li>{(t('send.acomptecheque1'))} {new Intl.NumberFormat("fr-FR", {
                                                            minimumFractionDigits: 0,
                                                            maximumFractionDigits: 0,
                                                            style: "currency",
                                                            currency: "EUR",
                                                        }).format(loccomputedOrderlines.reduce((a, b) => a + parseFloat(b.total_ttc), 0) * getConfigurationdepositpercent())}
                                                            {(t('send.acomptecheque2'))}                                                         </li>
                                                        <li>{(t('send.acomptecheque3'))}
                                                        </li><li>{(t('send.acomptecheque4'))}
                                                        </li>
                                                    </ul>
                                                    {(t('send.acomptecheque5'))}
                                                </Typography>
                                                <Button onClick={submitForm} disabled={isRegistrationValidated !== true || (createdOrder && createdOrder > 0) ? true : false || !cgvvalidated} color="primary" style={{ marginTop: "20px" }} variant="outlined" >{(t('send.sendregistration'))}</Button>
                                            </Grid>
                                        </Grid>}
                                        {paymentChoice.includes('acomptevirement') && <Grid container spacing={2} md={12}>
                                            <Grid item md={12} xs={12}>
                                                <Typography align="left">
                                                    <ul>
                                                        <li>{(t('send.acomptetransfert1'))}  {new Intl.NumberFormat("fr-FR", {
                                                            minimumFractionDigits: 0,
                                                            maximumFractionDigits: 0,
                                                            style: "currency",
                                                            currency: "EUR",
                                                        }).format(loccomputedOrderlines.reduce((a, b) => a + parseFloat(b.total_ttc), 0) * getConfigurationdepositpercent())}
                                                            {(t('send.acomptetransfert2'))}
                                                        </li>
                                                        <li>{(t('send.acomptetransfert3'))}
                                                        </li>
                                                        <li>{(t('send.acomptetransfert4'))}
                                                        </li>
                                                    </ul>
                                                    {(t('send.acomptetransfert5'))}

                                                </Typography>
                                                <Button onClick={submitForm} disabled={isRegistrationValidated !== true || (createdOrder && createdOrder > 0) ? true : false || !cgvvalidated} color="primary" style={{ marginTop: "20px" }} variant="outlined" >{(t('send.sendregistration'))}</Button>
                                            </Grid>
                                        </Grid>}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>

                    {/* // *** Pay O */}
                    <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')} disabled={!isRegistrationValidated || !cgvvalidated || orderTotalAmount > 0}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel4bh-content"
                            id="panel4bh-header">
                            <Typography className={classes.heading}>{(t('send.payzero'))}</Typography>
                            <Typography className={classes.secondaryHeading}></Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid spacing={2} item md={12} xs={12} container>
                                <Typography align="left">
                                    {(t('send.event'))}
                                </Typography>
                                <Grid item md={12} xs={12}>
                                    <Button onClick={submitFormFreeValidation} disabled={isRegistrationValidated !== true || (createdOrder && createdOrder > 0) ? true : false || !cgvvalidated} color="primary" style={{ marginTop: "20px" }} variant="outlined" >{(t('send.sendregistration'))}</Button>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>

                </CardContent>
            </Card >
            <h3 id="cgvanchor"></h3>

            <Dialog
                disableBackdropClick
                disableEscapeKeyDown
                open={captchaopen}
                aria-labelledby="form-dialog-title"
                // maxWidth="md"
                fullWidth
            > <DialogTitle id="form-dialog-title">
                    {(t('send.validateyourorder'))}
                </DialogTitle>
                <DialogContent>
                    <Grid container item md={12} xs={12}
                        direction="row-reverse"
                        justify="center"
                        alignItems="center" spacing={2} style={{ marginTop: "0px" }}>

                        <Grid item md={12} xs={12}>
                            {/* {<ReCAPTCHA
                                sitekey={configuration.recaptchasitekey}
                                onChange={onCaptchaChange}
                            /> */
                            }
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Divider />
                    {<Button onClick={onCaptchaChange} color="primary">Valider</Button>}
                    <Button onClick={handleCaptchaCancel} color="primary">{(t('send.cancelbutton'))}</Button>
                </DialogActions>
            </Dialog>
        </div >
    );

}